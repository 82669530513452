<template>
    <div class="c-wrapper">
        <div class="calendar"
             @mouseup="mouseUp"
             @mouseleave.stop="mouseUp"
        >
            <div class="calendar__title" @click="monthClickEvent(year)">{{ monthTitle }}</div>
            <div class="calendar__body">
                <div v-for="(day, key) in 7" :key="`title${day}`" class="calendar__day day__weektitle"
                     :style="{fontSize: weekTitleFontSizeAdjustLang}">{{ showDayTitle(key) }}
                </div>
                <div v-for="(dayObj, key) in showDays" class="calendar__day" :key="`day${key}`">
                    <div
                        @mouseover="dragDay(dayObj)"
                        @mousedown="mouseDown(dayObj)"
                        class="day posCellBox"
                        :class="classList(dayObj)">{{ dayObj.value }}
                      <span class="posDesc">{{dayObj.desc}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
    name: 'month-calendar',
    props: {
      showBox: {
        type: Boolean,
        default: () => false
      },
        activeDates: {
            type: Array,
            default: () => []
        },
        month: {
            type: [String, Number],
            default: () => dayjs().month() + 1
        },
        year: {
            type: [String, Number],
            default: () => dayjs().year()
        },
        lang: {
            type: String,
            default: 'en'
        },
        activeClass: {
            type: String,
            default: () => ''
        },
        prefixClass: {
            type: String,
            default: () => 'calendar--active'
        }
    },
    data() {
        return {
            showDays: [],
            isMouseDown: false
        }
    },
    computed: {
        weekTitleFontSizeAdjustLang() {
            const fontSizeMapping = {
                cn: '16px',
                en: '14px',
            }
            return fontSizeMapping[this.lang]
        },
        monthTitle() {
            const monthMapping = {
                cn: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
                en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            }
            return monthMapping[this.lang][this.month - 1]
        }
    },
    methods: {
        initCalendar() {
            if (!this.year || !this.month) return []
            const activeMonth = dayjs()
                .set('date', 1)
                .set('year', this.year)
                .set('month', this.month - 1)
            let firstDay = activeMonth.startOf('month').day() - 1
            if (firstDay < 0) firstDay += 7
            const lastDate = activeMonth.endOf('month').date()
            const weekRow = firstDay >= 5 ? 6 : 5
            const WEEK = 7
            let day = 0
            const fullCol = Array.from(Array(weekRow * WEEK).keys())
                .map(i => {
                    let value = firstDay <= i
                        ? day++ % lastDate + 1
                        : ''
                    return {
                        value,
                        active: false,
                        isOtherMonth: firstDay > i || day > lastDate
                    }
                })
            this.showDays = fullCol

            // 把 toggleDate 的内容合并在 initCalendar 里。
            this.activeDates.forEach(date => {
                let oDate

                if (typeof date === 'string') {
                    oDate = {
                        date: date,
                        className: this.activeClass
                    }
                } else if (typeof date === 'object') {
                    oDate = date
                }

                let dayjsObj = dayjs(oDate.date)
                if (dayjsObj.year() !== this.year) return
                let activeDate = dayjsObj.date()
                let row = Math.floor(activeDate / 7)
                let activeArrayKey = (activeDate % 7) - 1 + firstDay + 7 * row
                this.showDays[activeArrayKey].active = true // to array index
                this.showDays[activeArrayKey].className = oDate.className
                this.showDays[activeArrayKey].desc = oDate.desc
              let _this = this
              this.showDays = [..._this.showDays]

            })
        },
        showDayTitle(day) {
            const dayMapping = {
                cn: ['一', '二', '三', '四', '五', '六', '日'],
                en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            }
            return dayMapping[this.lang][day]
        },
        toggleDay(dayObj) {
            if (!this.showBox||dayObj.isOtherMonth) return
            this.$emit('toggleDate', {
                month: this.month,
                date: dayObj.value,
                selected: !dayObj.active,
                className: this.activeClass,
              desc:dayObj.desc
            })
        },
        dragDay(dayObj) {
            if (this.isMouseDown) this.toggleDay(dayObj)
        },
        mouseDown(dayObj) {
            this.toggleDay(dayObj)
            this.isMouseDown = true
        },
        mouseUp() {
            this.isMouseDown = false
        },
        classList(dayObj) {
            let oClassList = {
                'calendar__day--otherMonth': dayObj.isOtherMonth,
                [this.prefixClass]: dayObj.active
            }

            if (dayObj.active) oClassList[dayObj.className] = true

            return oClassList
        },
        monthClickEvent(year) {
            let monthYearInfo = {
                monthTitle: this.monthTitle,
                month: this.month,
                year: year
            }
            this.$emit('monthClickEvent', monthYearInfo)
        }
    },
    watch: {
        year(val) {
            this.initCalendar()
        },
        // 外层来的资料有变化时
        activeDates(after, before) {
            this.initCalendar()
        }
    },
    created() {
        this.initCalendar()
    }

}
</script>

<style lang="less" type="text/less">
.c-wrapper {
    padding: 10px;
}

.calendar {
    background-color: #fff;
    min-height: 295px;
    text-align: center;
    color: rgba(53, 60, 70, 0.8);
    border-radius: 2px;
    min-width: 0;
    position: relative;
    text-decoration: none;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    transition: transform 0.3s ease;
}

.calendar:hover {
    z-index: 2;
}

@media (min-width: 1024px) {
    .calendar:hover {
        transform: scale(1.15);
        box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.1);
    }
}

.calendar .calendar__title {
    font-weight: bold;
    flex: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(196, 196, 196, 0.3);
    font-size: 18px;
    height: 50px;
    margin-bottom: 12px;
    cursor: pointer;
}

.calendar .calendar__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 0px 20px 20px 20px;
    min-width: 194px;
}

.calendar .calendar__day {
    flex: 14.28%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 40px;
    color: #5db3d4;
}

.calendar .day__weektitle {
    color: rgba(53, 60, 70, 0.8);
}

.calendar .day {
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
}

.calendar .day:after {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    z-index: 1;
    background-color: transparent;
}

.calendar .day.calendar--active:after {
    background-image: url("./baseline-remove_circle-24px.svg");
    background-size: 100% 100%;
}

.calendar .day:not(.calendar__day--otherMonth):hover {
    //background-color: rgba(102, 102, 102, 0.1);
    border-radius: 5px;
    opacity: 0.6;
}

.calendar .day.calendar--active {
    background-color: rgba(255, 186, 186, 0.5);
    color: #bcbcbc;
}

.calendar .day.calendar--active.info {
    background-color: rgba(23, 162, 184, 0.8);
    color: #fff;
}

.calendar .day.calendar--active.info:after {
    background-image: url("./RecordIt.svg");
    background-size: 100% 100%;
}

.calendar .day.calendar--active.warning {
    background-color: rgba(255, 193, 7, 0.7);
    color: #fff;
}

.calendar .day.calendar--active.warning:after {
    background-image: url("./round-warning-24px.svg");
    background-color: rgba(234, 234, 234, 0.3);
    background-size: 100% 100%;
}

.calendar .calendar__day--otherMonth {
    color: #eaeaea;
    cursor: auto;
}

.workDay{
  background-color: #409eff!important;
  color: #fff!important;
  border: 1px solid #fff!important;
}
.workDay:after {
  background-image: none!important;
}

.posCellBox {
  position: relative;
}

.posDesc {
  position: absolute;
  bottom:-12px;
  font-size: 12px;
  color: #E6A23C;
  transform: scale(0.7);
}

</style>
