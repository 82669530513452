<template>
	<div class="cbox1">
		<div class="headbar">

			<div style="float: right;">

				<el-date-picker v-model="slctYear" type="year" :clearable="false" value-format="yyyy" placeholder="选择年"
					@change="changeYearElDate" size="small">
				</el-date-picker>
				<div style="margin-left: 20px;display: inline-block;">
					<el-button size="small" type="primary" plain @click="showOffworkBox" icon="el-icon-suitcase">
						打开设置
					</el-button>
					<el-tooltip content="请先打开设置，再配置考勤日期" placement="top" effect="light">
						<i class="el-icon-question" style="font-size: 20px; margin-left: 10px;"></i>
					</el-tooltip>
				</div>

				<div style="margin-left: 20px;display: inline-block;">
					<el-button size="small" type="primary" plain @click="initWorkDay" icon="el-icon-suitcase">
						初始化工作日
					</el-button>
					<el-tooltip content="此按钮会同步国家法定节假日" placement="top" effect="light">
						<i class="el-icon-question" style="font-size: 20px; margin-left: 10px;"></i>
					</el-tooltip>
				</div>



			</div>
			<div style="padding-top: 14px; padding-bottom: 14px;">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item>考勤设置</el-breadcrumb-item>
					<el-breadcrumb-item>考勤日期</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

		</div>
		<div style="height: 85%;">


			<div v-if="isShowBox">
				<el-form :model="EditItem" ref="EditItem" label-width="100px">
					<fieldset>
						<legend>设置提前下班时间</legend>
						<el-row class="flexStart">
							<el-col :span="10">
								<el-form-item label="是否工作日:" prop="gender_id">
									<el-radio-group size="small" v-model="EditItem.workday" @change="changeRadio">
										<el-radio :label="1">工作日</el-radio>
										<el-radio :label="0">休息日</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<el-col :span="10">
								<el-form-item v-if="EditItem.workday == 1" label="下班时间">
									<el-time-select size="small" v-model="EditItem.off_work" value-format="HH-mm"
										:picker-options="timeopt" placeholder="选择时间">
									</el-time-select>
								</el-form-item>
							</el-col>
							<el-col :span="4">
								<el-form-item label="" label-width="0">
									<el-button size="small" type="primary" plain @click="closeOffworkBox"
										icon="el-icon-document-closed">关闭
									</el-button>
									<el-button size="small" type="primary" @click="saveOffWork"
										icon="el-icon-document-checked">保存
									</el-button>
								</el-form-item>
							</el-col>
						</el-row>
					</fieldset>
				</el-form>
			</div>
			<el-row class="cBox1">
				<year-calendar class="cus_calendar_class" v-model="year" :activeDates.sync="activeDates" :lang="lang"
					prefixClass="calendar--active" :activeClass="activeClass" :showBox="isShowBox"
					:showYearSelector="showYearSelector" :hideWeekend="hideWeekend" :hideSunday="hideSunday"
					@changeYear="changeYear" @toggleDate="toggleDate" @dblclick="dblclick"></year-calendar>
			</el-row>
		</div>
	</div>
</template>
<script>
import YearCalendar from './calendar/YearCalendar'
import dayjs from 'dayjs'
export default {
	components: {
		YearCalendar
	},
	data() {
		return {
			slctYear: '2021',
			lang: 'cn', //
			year: 2023,
			activeDates: [
				// 	 '2021-02-13',
				// {
				// 	date: '2021-02-14',
				//   className: 'workDay'
				// },
				// {
				// 	date: '2021-02-15',
				// },
				// {
				// 	date: '2021-02-16',
				// }
			],
			activeClass: '',
			showYearSelector: true,
			hideWeekend: false,
			hideSunday: false,
			list365: [],
			timeopt: {
				start: '05:00',
				step: '00:05',
				end: '22:00'
			},
			EditItem: {
				workday: 1
			},
			isShowBox: false,
		};
	},
	watch: {
		year(newval, oldval) {
			this.slctYear = newval.toString()
			this.getData();
		}
	},
	mounted() {
		let _this = this
		let year = new Date().getFullYear()
		this.slctYear = year.toString()
		this.year = Number(year)
		this.$nextTick(() => {
			_this.getData();
		})
	},
	methods: {
		changeRadio(e) {
			if (e == 0) {
				this.EditItem.off_work = undefined
			}
		},
		getData() {
			let _this = this
			this.$http.post("/api/get_sign_workday", {
				year: Number(this.year)
			}).then(res => {
				let rData = res.data
				if (rData && rData.length > 0) {
					this.list365 = rData
					let arr = []
					rData.map(r => {
						let obj = {}
						if (r.workday) {
							obj.date = r.sdate
							obj.selected = true
							obj.className = 'workDay'
							if (r.off_work) {
								obj.off_work = r.off_work
							}
							arr.push(obj)
						}
					})
					this.$nextTick(() => {
						setTimeout(() => {
							_this.activeDates = arr
							_this.$forceUpdate()
						}, 200)

					})
				}
			})
		},
		changeYear(e) {
			console.log(e)
		},
		changeYearElDate(e) {
			this.year = Number(e)
		},
		toggleDate(dateInfo) {
			console.log(dateInfo)
		},
		dblclick(e) {
			console.log(e)
			//todo 双击日期单独设置
		},
		showOffworkBox() {
			this.isShowBox = true
			this.EditItem = {
				workday: 1
			}
			this.activeDates = []
			console.log(this.activeDates)
		},
		saveOffWork() {
			console.log(this.activeDates)
			let _this = this
			let ids = []
			let a = 0,
				lenA = this.list365.length
			let b = 0,
				lenB = this.activeDates.length
			let temp1 = `${this.year}-01-01`
			let temp2 = new Date(temp1);
			let max = temp2.getTime()
			for (a = 0; a < lenA; a++) {
				console.log(this.list365[a].sdate)
				for (b = 0; b < lenB; b++) {
					console.log(this.activeDates[b].date)

					if (this.list365[a].sdate == this.activeDates[b].date) {
						ids.push(this.list365[a].id)
					}
					let t1 = new Date(this.activeDates[b].date);
					let t2 = t1.getTime();
					max = (max < t2) ? t2 : max
				}
				let oDate1 = new Date(this.list365[a].sdate);
				if (oDate1.getTime() > max) {
					console.log(oDate1, max)
					break
				}
			}
			if (ids && ids.length > 0) {
				this.$http.post("/api/set_sign_workday", {
					ids: ids.join(','),
					workday: this.EditItem.workday,
					off_work: this.EditItem.off_work
				}).then(res => {
					this.$message.success('设置成功!')
					this.closeOffworkBox()
				})
			} else {
				_this.$message.warning('考勤日期不能为空，请选择日期！')
			}

		},
		closeOffworkBox() {
			this.isShowBox = false;
			this.activeClass = ''
			this.getData()
		},
		initWorkDay() {
			this.$confirm('确认同步国家法定节假日?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/sign_init_workday", {
					year: this.slctYear
				}).then(res => {
					this.$message({
						type: 'success',
						message: '正在同步，稍等1分钟后刷新页面即可!'
					});
				})

			}).catch(() => {

			});
		}
	}
}
</script>
<style lang="less" type="text/less">
.redDot {
	background-color: #a00;
	color: #fff;
}

.your_customized_wrapper_class {}

.cbox1 div {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.cus_calendar_class {

	//background-color: #0aa;
	color: #fff;
}

.cus_calendar_class.red {
	background-color: #a00;
	color: #fff;
}

.cus_calendar_class.red:after {
	background-image: url("./calendar/baseline-remove_circle-24px.svg");
	background-size: 100% 100%;
}

.cus_calendar_class.redDot {
	//background-color: #a00;
	//color: #fff;
}

.cus_calendar_class.redDot:after {
	background-image: url("./calendar/baseline-remove_circle-24px.svg");
	background-size: 100% 100%;
}

.cus_calendar_class.blue {
	background-color: #00a;
	color: #fff;
}

.cus_calendar_class.yellow {
	background-color: #ff0;
	color: #000;
}
</style>
